

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Form } from '../FormBuilder/Form';
import { Validators } from '../FormBuilder';
import { AuthService } from '../../services/AuthService';
import { NotificationManager } from '../../helpers/NotificationManager';

export class ConfirmAccountForm extends Component {
    constructor(props) {
        super(props);      
        
        this.state = {         
            showSuccess: false
        }

        // Url Params
        const query = new URLSearchParams(this.props.location.search);
        this.token = query.get('token'); 
        this.email = query.get('email'); 

        this.formDefinition = {
            fields: [
                {
                    name: "email",
                    label: "Email",
                    validators: [
                        Validators.required(),
                        Validators.emailFormat()
                    ],
                    value: this.email
                },
                {
                    name: "password",
                    label: "Password",
                    editor: "password",
                    validators: [
                        Validators.required(),
                        Validators.passwordFormat()
                    ]                    
                },
                {
                    name: "passwordConfirm",
                    label: "Confirm Password",
                    editor: "password",
                    validators: [
                        Validators.required(),
                        Validators.passwordFormat()
                    ]
                }
            ]
        };

        this.handleConfirmAccount = this.handleConfirmAccount.bind(this);

        this.AuthService = new AuthService();
    } 

    handleConfirmAccount(confirmAccount) {
        var model = {
            email: this.email,
            password: confirmAccount.password,
            passwordConfirm: confirmAccount.passwordConfirm,
            token: this.token
        }

        // Uses the password reset to assign a new password
        this.AuthService.resetPassword(model).then(
            response => {
               this.setState({ showSuccess: true });
            },
            error => {
                NotificationManager.error('Error occurred attempting to confirm account', 'Error', 10000);
            }
        );
    }

    render() {
        const showSuccess = this.state.showSuccess === true ? true : false

        return !showSuccess ? (
            <>
                <h5>Confirm Account</h5>
                <p>Your account has been confirmed!</p><p> Please enter your new password below, using at least:</p>
                <ul>
                    <li>one lowercase ('a'-'z')</li>
                    <li>one uppercase ('A'-'Z')</li>
                    <li>one digit ('0'-'9')</li>
                    <li>one non alphanumeric character</li>
                    <li>6+ characters</li>
                </ul>            
                <Form
                    definition={this.formDefinition}
                    handleSave={this.handleConfirmAccount}
                    
                />
            </>          
        ) : (
                <>
                    <h5>Confirm Account</h5>
                    <p>You can now succesfully access the system.</p>
                    <NavLink to="/">Return to Login</NavLink>
                </>
            );
    }    
}