import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export function DropdownField({ field, actions, options, disableDefaultKey }) {
    const hasOptions = options && options.length > 0;
    const isDisabled = disableDefaultKey ?? false;

    return (
        <FormGroup className={actions.getErrorClass(field)}>
            <Label for={field.name}>{field.label}&nbsp;&nbsp;&nbsp;<small>{actions.getErrorMessage(field)}</small></Label>
            <Input type="select" name={field.name} id={field.name} value={field.value} onChange={actions.handleChange}>
                {
                    isDisabled === true
                        ?
                        <>
                            {
                                hasOptions ? options.map((opt) => {
                                    return (
                                        <option key={opt.id} value={opt.id}>{opt.name}</option>
                                    );
                                }) : null
                            }
                        </>
                        :
                        <>
                            <option key="0" value="">- Select One -</option>
                            {
                                hasOptions ? options.map((opt) => {
                                    return (
                                        <option key={opt.id} value={opt.id}>{opt.name}</option>
                                    );
                                }) : null
                            }
                        </>
                }
            </Input>
        </FormGroup>
    );
}