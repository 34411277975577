import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Form } from '../FormBuilder/Form';
import { Validators } from '../FormBuilder';
import { AuthService } from '../../services/AuthService';
import { NotificationManager } from '../../helpers/NotificationManager';

export const ResetPasswordForm = (props) => {    
    const [showSuccess, setShowSuccess] = useState(false);

    // URL Variables
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token');
    const email = query.get('email');

    // Services
    const [authService] = useState(new AuthService());

    // Form Definition
    var formDefinition = {
        fields: [
            {
                name: "email",
                label: "Email",
                validators: [
                    Validators.required(),
                    Validators.emailFormat()
                ],
                value: email
            },
            {
                name: "password",
                label: "Password",
                editor: "password",
                validators: [
                    Validators.required(),
                    Validators.passwordFormat()
                ]
            },
            {
                name: "passwordConfirm",
                label: "Confirm Password",
                editor: "password",
                validators: [
                    Validators.required(),
                    Validators.passwordFormat(),
                ]
            }
        ]
    };
 
    const handleResetPassword = (resetPassword) => {
        var model = {
            email: email,
            password: resetPassword.password,
            passwordConfirm: resetPassword.passwordConfirm,
            token: token
        }

        authService.resetPassword(model).then(
            response => {
                setShowSuccess(true);
            },
            error => {
                NotificationManager.error('Error occurred attempting to handle reset password', 'Error', 10000);
            }
        );   
    }

    // Renders the form
    const showForm = showSuccess === true ? true : false;

    return !showForm ? (
        <>
            <h5>Reset Password</h5>
            <p>Please enter your new password below, using at least:</p>
            <ul>
                <li>one lowercase ('a'-'z')</li>
                <li>one uppercase ('A'-'Z')</li>
                <li>one digit ('0'-'9')</li>
                <li>one non alphanumeric character</li>
                <li>6+ characters</li>
            </ul>
            <Form
                definition={formDefinition}
                handleSave={handleResetPassword}
                
            />
        </>
    ) : (
            <>
                <h5>Password Reset</h5>
                <p>Your password has been reset.</p>
                <NavLink to="/">Return to Login</NavLink>
            </>
        );
}
