import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export function NumberField({ field, actions, minimum, maximum, step }) {
    return (
        <FormGroup className={actions.getErrorClass(field)}>
            <Label for={field.name}>{field.label}&nbsp;&nbsp;&nbsp;<small>{actions.getErrorMessage(field)}</small></Label>                            
            <Input type="number" min={minimum} max={maximum} step={step} name={field.name} id={field.name} value={field.value} onChange={actions.handleChange} />
        </FormGroup>
    );
}