import React from 'react';
import { NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Role } from '../../enums'
import { RoleCondition } from '../AuthApp/RoleCondition';

export function Navigation(props) {
    const isMobileNavigation = props.isMobileNavigation === true ? true : false;

    return (
        isMobileNavigation ?
            /* Mobile Navigation */
            <div className="mobile-nav">
                <li className="nav-item">
                    <NavLink to="/" className="nav-link">
                        <Icon.Home className="feather" />&nbsp;Dashboard
                     </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/mailboxes" className="nav-link">
                        <Icon.Inbox className="feather" />&nbsp; Mailboxes
                            </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/message-templates" className="nav-link">
                        <Icon.FileText className="feather" />&nbsp;  Message Templates
                            </NavLink>
                </li>
            </div>

            :
            /*  Desktop Navigation */
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link">
                                <Icon.Home className="feather" />&nbsp;Dashboard
                        </NavLink>
                        </li>
                    </ul>

                    <RoleCondition roles={[Role.SuperAdmin, Role.Admin]}>
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                            <span>Mailbox Manager</span>
                        </h6>

                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink to="/mailbox-manager/mailboxes" className="nav-link">
                                    <Icon.Inbox className="feather" />&nbsp; Mailboxes
                            </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/mailbox-manager/message-templates" className="nav-link">
                                    <Icon.FileText className="feather" />&nbsp;  Message Templates
                            </NavLink>
                            </li>
                        </ul>
                    </RoleCondition>

                    <RoleCondition roles={[Role.SuperAdmin]}>
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                            <span>Settings</span>
                        </h6>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink to="/users" className="nav-link">
                                    <Icon.Users className="feather" />&nbsp;Users
                            </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/roles" className="nav-link">
                                    <Icon.List className="feather" />&nbsp;Roles
                                </NavLink>
                            </li>                                
                        </ul>
                    </RoleCondition>
                </div>
            </nav>
    );
}