import React from 'react';
import _ from 'lodash';
import * as Enumerable from 'linq-es2015';
import { Row, Col, Button, Form } from 'reactstrap';
import { ErrorList } from '../Common/ErrorList';
import StringHelper  from '../../helpers/string';
import { FieldEditor } from './';

export class BaseForm extends React.Component {
    constructor(props, formDefinition, formData) {
        super(props);

        // Create an object to act as a container to hold various flags.
        // This object is just a conevenience container to group all of the flags together.
        this.flags = {
            // Inidicates whether or not the getInitialForm() method has been called or not.
            isFormSetupInitialised: false,

            // Inidicates whether or not the getInitialFields() method has been called or not.
            isFieldsInitialised: false
        };

        // Setup the form and add it to the default state.
        // The setupForm() method will need to be defined within the derived class and should setup the form object accordingly and return it.
        const form = {
            ...this.getInitialForm(formDefinition, formData)
        };

        // Retrieve an array of the names of each of the fields within the form.
        // We will need these names to be able to reference each field.
        // We are doing it this way as at this point we won't really know what the fields actually are.
        const fieldNames = Object.keys(form.fields);

        // Check if the form feilds have already been initialised or not.
        // If the haven't, then we will want to ensure that the fields have the minimum required properties set (i.e. name, label etc.).
        if(!this.flags.isFieldsInitialised) {
            // TODO:
            // More testing of this part needs to be done.

            // Loop through each name and set the name property on each field.
            // The name property should be set to the name of the current field.
            // We are adding this property for convenience, so that it is easier to determine what the field actually is.
            _.forEach(fieldNames, (name) => {
                const field = form.fields[name];
                field.name = name;
                field.label = StringHelper.getString(field.label, name);
            });
        }
        
        // Set the default state.
        this.state = {
            form: {
                ...form
            }
        };

        // Bind the functions that will be passed to the other components.
        // Doing this means that the function will be executed within the context of this object.
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);    

        // Define an object to act as a container to hold all of the field actions
        this.fieldActions = {
            handleChange: this.handleChange,
            getErrorClass: this.getErrorClass.bind(this),
            getErrorMessage: this.getErrorMessage.bind(this),
        }
    }

    getInitialForm(formDefinition, formData) {
        // Set the flag to indicate that the method to initialise the form has been run.
        // We will use this flag elsewhere to identify if this method has been called or not.
        this.isFormSetupInitialised = true;

        // Generate the fields object ocntainer.
        // The field are generated using the getInitialFields() method.
        // This takes two parameters:
        //  - fieldDefinitions: An array of Field Definitions defining how a feild should be created/represented. Items can be a String or an Object (with the relevant properties) 
        //  - data: An object with properties that match the form fields. Form field values will be populated from the coresponding proeprty on this object.
        const fields = this.getInitialFields(formDefinition.fields, formData);

        // Retrun the object representing the form.
        return {
            isValid: false,
            isDirty: false,
            isSubmitAttempted: false,
            fields: fields
        }
    }

    getInitialFields(fieldDefintions, data) {
        // This function will generate the relevant form fields within an object container.
        // The fields will be based on the definitions supplied through the "fieldDefintions" parameter.
        // The "data" parameter should be an object that holds the relevant values for the corresponding form fields.
        //  - The benefit of supporting the data parameter is that this will allow us to pre-poulate the form when the field definitions are strings
        //  - This means that when the field definition is a string, we cannot obtain a field value from that definition.

        // Set the flag to indicate that the method to initialise the fields has been run.
        // We will use this flag elsewhere to identify if this method has been called or not.
        this.flags.isFieldsInitialised = true;

        // Create an object that will act as a container to hold all of the fields.
        // Please note that this is NOT an array, but instead and object containing named fields (i.e. fields are objects).
        const fields = {};

        // Loop through each of the field definitions and generate the relevant field accordingly.
        // Each generated field will be added to the fields object as a property, using the name of the field as the property name.
        _.forEach(fieldDefintions, (fieldDef) => {
            // Ensure that the fieldDef is not undefined or null.
            // If we didn't check for this an error could be thrown when we attempt to access it.
            if(fieldDef) {
                // Create the field object that will be used to represent the current form field.
                let field = {};

                // The field definition can be supplied in one of two formats:
                //  - String
                //  - Object

                // Generate the field from a string definition.
                // This definition will create a basic field with default settuings
                if(typeof fieldDef === 'string') {
                    // Create the field and set it using the default template.
                    field = fields[fieldDef] = {
                        ...this.getDefaultFieldModel(fieldDef)
                    };
                }

                // Generate the field from an object definition.
                // This definition will create a field with multiple settings set accordingly
                else if(typeof fieldDef === 'object') {
                    // Create the field and set it using the default template.
                    // We will modify this later, if we need to.                   
                    field = fields[fieldDef.name] = {
                        ...this.getDefaultFieldModel(fieldDef.name, fieldDef.value)
                    };
                }

                // In order to support auto-complete, we will ensure that the auto-complete property is set where relevant.
                if(fieldDef.autoComplete) {
                    field.autoComplete = fieldDef.autoComplete
                }

                if(fieldDef.editor) {                    
                    if(typeof fieldDef.editor === 'string') {
                        field.editor = {
                            name: fieldDef.editor
                        }
                    }
                    else if(typeof fieldDef.editor === 'object') {
                        field.editor = fieldDef.editor;
                    }
                }

                if(fieldDef.validators && fieldDef.validators.length > 0) {
                    field.validators =  fieldDef.validators;
                }

                // Determine how we should set the value of the field.
                //  - From the supplied "data" object
                //  - From the field definition
                // Note: this will override the value specified by the field definition.
                if(data && typeof(data) === 'object') {
                    // Set the value of the field from the data supplied.
                    // If not supplied, then default to an empty string.
                    var dataValue = StringHelper.ensureString(data[field.name]);
                    field.value = StringHelper.getString(dataValue, field.value);
                }
                
                // Set the label for the field.
                // If no label has been specified, we will use the field name as the default.
                field.label = StringHelper.getString(fieldDef.label, field.name);

                // We need to check that the value that has been set is relevant for the type of editor being used.
                if(field.editor.name === 'checkbox') {                    
                    field.value = StringHelper.getBoolean(field.value.toString());                    
                }

                // Determines the value of the multitentantcheckbox
                if (field.editor.name === 'multitentantcheckbox') { 
                    var value = data ? data[field.name] : [];
                    field.value = StringHelper.getIntArray(value);                                     
                }
            }
        });      

        return fields;
    }

    getDefaultFieldModel(fieldName, fieldValue) {
        // The value supplied through "fieldValue" will be used as the default value for the field.
        // We will check if there is NOT a valid value for this.
        // If the the value for this NOT valid, then we will assign an empty string value is assigned.
        let defaultValue = fieldValue;
        
        if((!fieldValue) || typeof fieldValue === "object") {
            defaultValue = '';
        }

        // Create and return an object that will represent a field in it's inital state.
        return {
            name: fieldName,
            value: defaultValue,
            isValid: true,
            isValidated: false,
            isDirty: false,
            errorMessage: '',
            validators: [],
            editor: {
                name: "text"
            }
        };
    }

    componentDidMount() {
        // Validate each of the fields defined on the form.
        this.validateAllFields(this.state.form.fields);
    }

    updateFormState(form, afterSetStateCallback) {
        // Update the form within state using the form object provided.
        // Also, if a calback has been provided through the "afterSetStateCallback", then we will call this once the state has been updated.
        this.setState({
            form: {
                ...form
            }
        }, afterSetStateCallback);
    }    

    handleChange(e) {
        // Get the name of the field that has changed along with the value that has been set for this field.
        // We will need to know the name, so that we can set the relevant value on the corresponding form field within the state.
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;    

        // Retrieve the form object from the state.
        // As a field within the form has changed, we should set the isDirty flag to true to inidicate that the form has been modified.
        const form = this.state.form;
        form.isDirty = true;    

        // Update the relevant form field with the specified value.
        // We also need to set the isDirty flag to true to inidicate that the field has been modified.
        form.fields[name] = {
            ...form.fields[name],
            value: value,
            isDirty: true,
            isValid: true,
        };

        // TODO: PUT THIS ELSEWHERE! FINE FOR NOW.
        // PASSWORD LOGIC
        var password = form.fields["password"]; 
        var passwordConfirm = form.fields["passwordConfirm"];

        if (password !== undefined && passwordConfirm !== undefined) {
            if (passwordConfirm.value !== password.value) {
                passwordConfirm.isValid = false;
                passwordConfirm.errorMessage = 'Does not match password';
            }
            else {
                passwordConfirm.isValid = true;
                passwordConfirm.errorMessage = '';
            }
        }       

        // Update the form object within the state.
        // As the updated field is within the form object, the field will also be updated within the state.
        // Once the satte has been updated, the field will then be validated.
        this.updateFormState(form, () => { 
            this._validateField(form.fields[name]); 
        });
    }

    handleSubmit(e){
        // Stop the form from being submitted.
        // We do not want the form to submit as we will handle the submission of the data ourselves.
        e.preventDefault();

        // Get the form and the fields from the state.
        // We need these as it will make referencing the form and the relevant fields a lot easier.
        const form = this.state.form;
        const fields = this.state.form.fields;

        // Set the flag to indicate that there has been an attempt to submit the form.
        form.isSubmitAttempted = true;

        // Check if the form has been modified at all.
        // We need to check this so that if it has NOT been modified then we can update the isDirty flag on the form.
        // The reason for doing this is that the error messages are are partly dependant on the form.isDirty flag being true for these to be displayed.
        if(!form.isDirty) {
            form.isDirty = true;
            this.updateFormState(form);
        }        

        // Check if the form is valid or not.
        // The form.isValid flag should indicate whether this is valid or not as this flag will have been set accordingly during the validation process.
        if(form.isValid) {
            // Build a model using data from the form fields.
            // This is required as we will need this to passs it to the handleSave() function for the data to be processed accordingly.
            // The getModel() method will need to be defined within the derived class and should map the form field values to the relevant object and return it.
            const model = this.getModel(fields);
            this.props.handleSave(model);
        }
    }

    getModel(fields) {
        return this._getModel(fields);
    }

    _getModel(fields){
        // NOTE:
        // This method can be overridden within the derived class.
        // This should be done when the model mapping should be done in a different way.

        // This function will map the data from the form to an object model.
        // The properties on this model will use the same names defined for each field within the form.
        // The value for each property will come from the "value" property on each field.
        const model = {};

        // Retrieve an array of the names of each of the fields within the form.
        // We will need these names to be able to reference each field.
        // We are doing it this way as at this point we won't really know what the fields actually are.
        const fieldNames = Object.keys(fields);

        // Loop through each field name and generate a matching property on the model.
        _.forEach(fieldNames, (fieldName) => {
            model[fieldName] = fields[fieldName].value;
        });
        
        return model;
    }

    _validateField(field) {
        const form = this.state.form;
        const validators = field.validators;

        if(_.isArray(validators)) {
            _.forEach(validators, (validator) => {
                if(validator) {
                    if(typeof validator === 'function') {
                        validator(field);
                        
                    }
                    else if(validator.validate && typeof validator.validate === 'function') {
                        validator.validate(field);
                    }
                }            
            });
        }
        else if(_.isFunction(validators)) {
            validators(field);
        }
        
        // Assign the fields to the form.
        // We need to do this so that when we update the form within state,
        // The changes to the relevant fields will be applied.
        form.fields = {
            ...form.fields,
            [field.name]: { ...field }
        };

        this.updateFormState(form, () => { 
            this.validateForm(form); 
        });
    }

    validateAllFields(fields) {
        // Retrieve an array of the names of each of the fields within the form.
        // We will need these names to be able to reference each field.
        // We are doing it this way as at this point we won't really know what the fields actually are.
        const fieldNames = Object.keys(fields);

        // Loop through each field and perform the relevant validation for that field.
        // This should set the relevant properties on each field accordingly depending on the result of the validation.
        // This means that it will set the isValid flag and possibly the errorMessage depending on the outcome.
        _.forEach(fieldNames, (fieldName) => {
            this._validateField(fields[fieldName]);
        });
    }

    validateForm(form) {
        const fields = form.fields;

        // Set the form.isValid flag to be true by default.
        // We will change this to be false later if any of the form fields are not valid.
        form.isValid = true;

        // Retrieve an array of the names of each of the fields within the form.
        // We will need these names to be able to reference each field.
        // We are doing it this way as at this point we won't really know what the fields actually are.
        const fieldNames = Object.keys(fields);

        // Check the isValid flag on each of the relevant fields within the form.
        // They will all need to be true in order to set the form.isValid flag to true.
        // if at least one of the feilds has it's isValid flag set to true, then the form .isValid flag will be set to true. 
        _.forEach(fieldNames, (fieldName) => {
            if(!fields[fieldName].isValid) {
                form.isValid = false;
            }
        });

        // Update the state to reflect the reult of the validation check
        this.updateFormState(form);
    }

    getErrorClass(field) {
        const form = this.state.form;

        // Get the className that is to be used.
        // If a value for errorClassName has been provided through the props object,
        // Then we will use that for the className, otherwise will will use the default value of 'has-error'.
        const errorClassName = this.props.errorClassName && this.props.errorClassName.length > 0 ? this.props.errorClassName : 'has-error';

        // Check the specified field to see if it is valid or not.
        // If it is NOT valid then we will return a string representing the className to use for the form field element to highlight the error.
        // The className will only be returned if the field has actually been modified.
        return (form.isSubmitAttempted && !field.isValid) || ((!field.isValid) && field.isDirty) ? errorClassName : '';
    }

    getErrorMessage(field) {
        const form = this.state.form;

        // Check the specified field to see if it is valid or not.
        // If it is NOT valid then we will return a string representing the error message tthat will be used to provide error feedback.
        // The error message will only be returned if the field has actually been modified.
        return (form.isSubmitAttempted && !field.isValid) || ((!field.isValid) && (field.isDirty)) ? field.errorMessage : '';
    }

    getErrorItems(fields) {
        // Retrieve an array of the names of each of the fields within the form.
        // We will need these names to be able to reference each field.
        // We are doing it this way as at this point we won't really know what the fields actually are.
        const fieldNames = Object.keys(fields);

        // Loop through each field.
        // Check if the field is currently valid or not by checking the isValid flag.
        // The result will be an array of fields that are currently NOT valid.
        const errorFields = fieldNames.map((name) => {
            return (fields[name] && fields[name].isValid === false) ? fields[name] : undefined;
        });

        // Now, we need to retrieve only the error messages from each of the fields.
        // The result will be an array of strings (i.e. error messages).
        const errors = Enumerable.AsEnumerable(errorFields).Where(x => x && x.errorMessage && x.errorMessage.length > 0).Select(x => x.errorMessage).ToArray();
        return errors;
    }

    renderForm(form) {
        // Get the JSX representing the form fields
        // The renderFormFields() method will need to be defined within the derived class.
        // We will output this content within the form.
        const formFieldsContent = this.renderFormFields(form.fields, form);

        // Get the JSX representing the form buttons (i.e. Save/Submit button)
        // The renderFormButtons() method will need to be defined within the derived class.
        // We will output this content within the form.
        const formButtonsContent = this.renderFormButtons(form);

        // Get the JSX representing the form errors list
        // The renderFormErrors() method can be overridden within the derived class.
        // We will output this content within the form.
        const formErrorsContent = this.renderFormErrors(form);

        // Build and return the JSX representing the form.
        // The error list will only be displayed if there has been a change to the form, the form is valid and there are items within the errorItems array.
        // The form buttons will only be displayed if the hideSaveButton flag from the props object is set to false.
        return (
            <Form onSubmit={this.handleSubmit}>
                { formErrorsContent }                
                { formFieldsContent }
                { formButtonsContent }
            </Form>
        );
    }

    renderFormFields(fields, form) {
        const fieldNames = Object.keys(fields);
        return (
            <Row form>
                <Col xs="12">   
                    {
                        fieldNames.map((fieldName, index) => {
                            const field = fields[fieldName];
                            return <FieldEditor key={index} field={field} actions={this.fieldActions} />;
                        })
                    }
                </Col>
           </Row>
        );
    }

    renderFormButtons(form) {
        // Get the text that will be used for the Submit Button
        const buttonText = this.props.submitButtonText && this.props.submitButtonText.length > 0 ? this.props.submitButtonText : "Submit";
        const buttonColor = this.props.submitButtonColor && this.props.submitButtonColor.length > 0 ? this.props.submitButtonColor : "success";
        // Identify how the button should be positioned.
        // To position the button, we will do this by assigning the relevant className to the button's container.
        let buttonContainerClassName = null;
        if(this.props.submitButtonPosition === "left") {
            buttonContainerClassName = "float-left";
        }
        else if (this.props.submitButtonPosition === "right") {
            buttonContainerClassName = "float-right";
        }

        // This JSX will render the form's buttons (i.e. Save/Submit) and will only be rendered there is no renderFormButtons() method defined within the derived class.
        // The derived class must define a renderFormFields() method to render the alternative buttons.
        return !this.props.hideSubmitButton ? (
            <Row>
                <Col>                    
                    <div className={buttonContainerClassName}>
                        <Button color={buttonColor} disabled={!form.isValid} style={this.props.submitButtonPosition === "full-width" ? { width: '100%' } : null}> 
                            {buttonText}
                        </Button>
                    </div>                     
                </Col>
            </Row>
        ) : null;
    }

    renderFormErrors(form) {
        // Retrieve the error items from the relevant form fields.
        // We will only obtain these items if the  flag from the props object is set to false.
        // If there is no items, the we should not display the ErrorList component later on
        const errorItems = this.props.disableErrorList ? [] : this.getErrorItems(form.fields);

        // This JSX will render the form's errors list and will only be rendered there is no renderFormErrors() method defined within the derived class.
        // The derived class must define a renderFormErrors() method to render an alternative version of the errors list.
        return (
            form.isDirty && (!form.isValid) && errorItems.length > 0
            ? (
                <Row>
                    <Col>
                        <ErrorList items={errorItems} introText="Fix the errors now!" />
                    </Col>
                </Row>
            ) : null
        );
    }

    render() {
        // Render the form using the form object from the current state!
        return this.renderForm(this.state.form);
    }
}