import React from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { ConfirmDeleteRoleModal } from './ConfirmDeleteModal';

export class RoleActionsButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            isConfirmDeleteModalOpen: false,
            isRolePassordModalOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
    }

    toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleConfirmDeleteModal() {
        this.setState({
            isConfirmDeleteModalOpen: !this.state.isConfirmDeleteModalOpen
        });
    }

    handleDeleteConfirmed(entity, e) {
        this.props.handleDelete(entity, e);
    }

    render() {
        return (
            <>
                <div className="float-right">
                    <ButtonDropdown direction="left" isOpen={this.state.dropdownOpen} toggle={this.toggle} size="sm">
                        <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={(e) => this.props.handleEdit(this.props.role, e)}><Icon.Edit2 className="feather" />&nbsp; Edit</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={this.toggleConfirmDeleteModal}><Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>

                <ConfirmDeleteRoleModal 
                    isOpen={this.state.isConfirmDeleteModalOpen}
                    toggle={this.toggleConfirmDeleteModal} 
                    handleConfirm={this.handleDeleteConfirmed}
                    role={this.props.role}
                />
            </>
        );
    }
}