import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    };

    handleConfirm(e) {
        this.props.toggle(e);

        if(this.props.handleConfirm) {
            this.props.handleConfirm(e);
        }
    }

    handleCancel(e) {
        this.props.toggle(e);
        
        if(this.props.handleCancel) {
            this.props.handleCancel(e);
        }
    }

    render() {
        const modalTitle = this.props.title &&  this.props.title.length > 0 ?  this.props.title : "Confirm!";

        const confirmText = this.props.confirmText && this.props.confirmText.length > 0 ? this.props.confirmText : "OK";
        const confirmColor = this.props.confirmColor && this.props.confirmColor.length > 0 ? this.props.confirmColor : "success"
        const confirmIcon = this.props.confirmIcon ? (<span><this.props.confirmIcon />&nbsp;&nbsp;</span>) : null

        const cancelText = this.props.cancelText && this.props.cancelText.length > 0 ? this.props.cancelText : "Cancel";
        const cancelColor = this.props.cancelColor && this.props.cancelColor.length > 0 ? this.props.cancelColor : "secondary"

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className} size={this.props.size}>
                    <ModalHeader toggle={this.props.toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={cancelColor} onClick={this.handleCancel}>{cancelText}</Button>
                        &nbsp;&nbsp;
                        <Button color={confirmColor} onClick={this.handleConfirm}>
                            {confirmIcon}
                            {confirmText}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}