import React, { useState, useEffect } from 'react';
import { NotificationManager } from '../../helpers/NotificationManager';
import { MailboxService } from '../../services/MailboxService';
import { LookupService } from '../../services/LookupService';
import { OutOfOfficeStatusForm } from './OutOfOfficeStatusForm';
import { SimpleLoader } from '../Common/SimpleLoader';
import { OOOStatus } from '../../enums/OOOStatus'

export const OutOfOfficeStatus = props => {
    const [outOfOfficeSettings, setOutOfOfficeSettings] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [isStatusesLoaded, setIsStatusesLoaded] = useState(false);
    const [isFormLoaded, setIsFormLoaded] = useState(false);

    const userId = props.mailbox != null ? props.mailbox.id : 0;

    // Init.  
    useInitMount(userId);
    function useInitMount(userId) {
        useEffect(() => {
            const mailboxService = new MailboxService();
            const lookupService = new LookupService();

            // Load user template
            mailboxService.getById(userId).then(
                response => {
                    const outOfOfficeSettings = {
                        statusId: response.data.outOfOffice.status.id,
                        startDate: response.data.outOfOffice.start,
                        endDate: response.data.outOfOffice.end
                    }
                    setOutOfOfficeSettings(outOfOfficeSettings);
                    setIsFormLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain out of office settings', 'Out of Office Settings :: Error', 10000);
                }
            )

          // Loads statuses
          lookupService.getByType("OutOfOfficeStatus").then(
            response => {
                const statuses = response.data.results.map(status => {
                    return {
                        id: status.id,
                        name: status.name
                    };
                });

                setStatuses(statuses);
                setIsStatusesLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain statuses', 'Statuses :: Error', 10000);
            }
        );
        }, [userId]);
    }

    const handleSave = (e) => {
        const mailboxService = new MailboxService();
        var status = OOOStatus[e.statusId];

        var model = {
            status: status,
            start: e.start,
            end: e.end,
            isSendConfirmationEmailEnabled: true
        };

        if (status === "Disabled") {
            mailboxService.disableOutOfOffice(userId).then(
                response => {
                    NotificationManager.success("The out of office was disabled", 'Disabled');
                },
                error => {
                    NotificationManager.error('Could not disable the out of office', 'Out of Office :: Error', 10000);
                }
            );
        } else {
            mailboxService.setOutOfOffice(userId, model).then(
                response => {
                    NotificationManager.success("The out of office was enabled", 'Enabled');
                },
                error => {
                    NotificationManager.error('Could not enable the out of office', 'Out of Office :: Error', 10000);
                }
            )
        }
    }

    const isLoaded = isStatusesLoaded && isFormLoaded;

    return (
        <SimpleLoader active={!isLoaded} spinner text="Loading...">
            <OutOfOfficeStatusForm
                mailbox={props.mailbox}
                data={outOfOfficeSettings}
                handleSave={handleSave}
                statusOptions={statuses}
            />
        </SimpleLoader>
    );
}