import axios from 'axios';
import moment from 'moment';
import config from '../config';
import { Keys } from '../enums';
import { EncodedStorageService } from './EncodedStorageService';

// NOTE:
// When storing values within local storage Convert the user object to a JSON string.
// We will also Base 64 Ecode the JSON string.
// This is to make it harder to edit/manuipulate within the developer tools to prevent someone modifying it.
// This is not 100% full-proof, but it will discourage this kind of thing.

export class AuthService {
    constructor() {
        // Create an instance of the storage service.
        // This is what we will use to store and retrieve the authenticated user data.
        // This storage service will encode all data that is stored.
        this.storage = new EncodedStorageService();

        this.baseUrl = config.baseApiUrl + "Auth/";
        this.tokenEndpoint = this.baseUrl + "Token/";
    }

    setAuthUser(user, token) {
        // Save the authenticated user to storage.
        this.storage.save(Keys.AuthUser, user);

        if (token){
            this.storage.save(Keys.AuthToken, token);
        }        
    }

    getAuthUser() {
        return new Promise((resolve, reject) => {
            // TO DO -- 
            // Retrieve authtoken to see if it exists, and include it in logic
            // if this.storage.authtoken exists, assume that it has a value
            // check if it has a value greater than 0
            // wont return authauser unless it has both keys

            // Check if the authenticated user exists in storage.
            if(this.storage.exists(Keys.AuthUser))
            {
                // Retrieve the authenticated user from the storage.
                const authUser = this.storage.get(Keys.AuthUser);         
                if(authUser) {
                    // Check the expiry date of the token.
                    // If it has expired, remove the user data from the local storage and return a rejection.
                    const now = moment();
                    const expiryDate = moment(authUser.expires);

                    // TODO:
                    // Maybe we can get an updated token using a refresh token here if/wehen required.
                    // We would need to check if the token is due to expire soon.
                    // If it is, then we would attempt to get an updated token at that point using a refresh token.

                    const isTokenExpired = now.isAfter(expiryDate);                    
                    if(!isTokenExpired) {
                        // Token has NOT expired.
                        resolve({
                            data: {
                                ...authUser
                            }
                        });
                    }
                    else
                    {
                        // Token HAS expired
                        // Remove the user data from storage
                        this.storage.delete(Keys.AuthUser);
                        this.storage.delete(Keys.AuthToken);

                        // Return a rejection with the relevant error.
                        reject({
                            data: {
                                errors: [
                                    {
                                        code: "authentication-error",
                                        message: "Token has expired"
                                    }
                                ]
                            }
                        });
                    }
                }
            }            

            // If this point has been reached,
            // The the authenticated user was not obatined.
            reject({
                data: {
                    errors: [
                        {
                            code: "authentication-error",
                            message: "Could not obtain authenticated user"
                        }
                    ]
                }
            });
        });
    }

    // Gets the current User's details
    getUserDetails(){
        const endpoint = this.baseUrl + "UserDetails/";
        return axios.get(endpoint);
    }

    login(username, password) {
        const model = {
            username: username,
            password: password
        };

        return axios.post(this.tokenEndpoint, model);
    }

    logout() {
        return new Promise((resolve, reject) => {
            // Remove the authenticated user from storage.
            this.storage.delete(Keys.AuthUser);
            this.storage.delete(Keys.AuthToken);

            // Check that the user has been removed from storage and returne the relevant response.
            const authUser = this.storage.get(Keys.AuthUser);
            if(authUser) {
                reject({
                    data: {
                        errors: [
                            {
                                code: "logout-error",
                                message: 'Authenticated user was not removed from storage'
                            }
                        ]
                    }
                });
            }
            else
            {
                resolve({});
            }
        });
    }

    forgotPassword(model) {
        const endpoint = this.baseUrl + "ForgotPassword";
        return axios.post(endpoint, model);        
    }

    resetPassword(model) {
        const endpoint = this.baseUrl + "ResetPassword";
        return axios.post(endpoint, model);        
    }
}