import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { BasicModal } from '../Modals/Basic';
import { Can } from '../RBAC/Can';
import { OutOfOfficeStatus } from '../OutOfOfficeSettings/OutOfOfficeStatus';
import { OutOfOfficeSettings } from '../OutOfOfficeSettings/OutOfOfficeSettings';

export const MailboxActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isMailboxModalOpen: false,
        isOOOModalOpen: false,
        isSettingsModalOpen: false
    });

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    const toggleOOOModal = (entity, e) => {
        setState((prevState) => ({
            ...prevState,
            isOOOModalOpen: !prevState.isOOOModalOpen
        }));     
    };

    const toggleSettingsModal = (entity, e) => {
        setState((prevState) => ({
            ...prevState,
            isSettingsModalOpen: !prevState.isSettingsModalOpen
        }));     
    };

    return (
        <>
            <Can
                perform="mailboxes:edit"
                yes={() => (
                    <>
                        <div className="float-right">
                            <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                                <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={toggleOOOModal}><Icon.User className="feather" />&nbsp; Enable/Disable OOO</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={toggleSettingsModal}><Icon.Settings className="feather" />&nbsp; Template Settings</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>

                        {/* Modal */}
                        <BasicModal
                            isOpen={state.isOOOModalOpen}
                            toggle={toggleOOOModal}
                            title="Out of Office: Enable or Disable"
                        >
                            <OutOfOfficeStatus mailbox={props.mailbox} />
                        </BasicModal>

                        {/* Modal */}
                        <BasicModal
                            isOpen={state.isSettingsModalOpen}
                            toggle={toggleSettingsModal}
                            title="Out of Office: Template Settings"
                        >
                            <OutOfOfficeSettings mailbox={props.mailbox}/>
                        </BasicModal>
                    </>
                )}
            />           
        </>
    );
}

