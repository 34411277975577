import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { MessageTemplateForm } from './Form';
import { MessageTemplateService } from '../../services/MessageTemplateService';

export class CreateMessageTemplateContainer extends React.Component {
   constructor(props) {
       super(props);

       this.MessageTemplateService = new MessageTemplateService();
       
       this.createMessageTemplate = this.createMessageTemplate.bind(this);
   }

   createMessageTemplate(messageTemplate) {
       var model = {
          ...messageTemplate
       };

       this.MessageTemplateService.create(model).then(
           response => {
               const messageTemplate = response.data;
               this.props.history.push('/mailbox-manager/message-templates/edit/' + messageTemplate.id);
               NotificationManager.success('The message template ' + messageTemplate.name + 'was created successfully', 'Message Template Created');
           },
           response => {
               NotificationManager.error('Message Template was not created', 'Error', 10000);
           },
       );
   }

   render() {
       const isLoaded = true;

       // Build the Screen menu.
       // This will be passed to the Screen component so that it can be rendered in the relevant place.
       const screenMenu = (
           <>
               <NavLink  to="/mailbox-manager/message-templates" className="btn btn-sm btn-outline-secondary">
                   <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Message Templates List
               </NavLink>
           </>
       );

       // Define the setting for the screen loader
       // This object will be passed to the Screen component,
       // Which will determine how and if the loader should be displayed
       const screenLoader = {
           isEnabled: true,
           isLoaded: isLoaded,
           text: "Loading Create Message Template Form..."
       };

       return (
           <Screen title="Create Message Template" menu={screenMenu} loader={screenLoader}>
               <MessageTemplateForm
                   ref={(el) => this.messageTemplateForm = el}
                   handleSave={this.createMessageTemplate}
               />
           </Screen>
       );
   }
}