import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { CreateUserForm } from './CreateForm';
import { UserService } from '../../services/UserService';
import { EntityService } from '../../services/EntityService';
import { AuthService } from '../../services/AuthService';

export class CreateUserContainer extends React.Component {
   constructor(props) {
       super(props);

       // Set default state
       this.state = {
           roles: [],
           isRolesLoaded: false
       };

       this.AuthService = new AuthService();
       this.UserService = new UserService();
       this.EntityService = new EntityService("Roles");   
       
       this.createUser = this.createUser.bind(this);
   }

    componentDidMount() {
        this.EntityService.getList(1, 1000, "Name", "asc").then(
            response => {
                const roles = response.data.results.map(role => {
                    return {
                        id: role.id,
                        name: role.name
                    };
                });
                this.setState({
                    roles: roles,
                    isRolesLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain roles', 'Error', 10000);
            }
        );       
    }

   createUser(user) {
       var model = {
          ...user
       };

       this.UserService.create(model).then(
           response => {
               const user = response.data;
               this.props.history.push('/users/edit/' + user.id);
               NotificationManager.success('The user ' + user.firstName + ' ' + user.surname + ' was created successfully', 'User Created');
           },
           response => {
               NotificationManager.error('User was not created', 'Error', 10000);
           },
       );
   }

   render() {
       const isLoaded = this.state.isRolesLoaded;

       // Build the Screen menu.
       // This will be passed to the Screen component so that it can be rendered in the relevant place.
       const screenMenu = (
           <>
               <NavLink  to="/users" className="btn btn-sm btn-outline-secondary">
                   <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Users List
               </NavLink>
           </>
       );

       // Define the setting for the screen loader
       // This object will be passed to the Screen component,
       // Which will determine how and if the loader should be displayed
       const screenLoader = {
           isEnabled: true,
           isLoaded: isLoaded,
           text: "Loading Create User Form..."
       };

       return (
           <Screen title="Create User" menu={screenMenu} loader={screenLoader}>
               <CreateUserForm
                   ref={(el) => this.userForm = el}
                   handleSave={this.createUser}                   
                   roleOptions={this.state.roles}
               />
           </Screen>
       );
   }
}