import axios from 'axios';
import { EntityService } from './EntityService';

export class MailboxService extends EntityService {
    constructor() {
        super("ActiveDirectory/Mailboxes");
    }

    setOutOfOffice(id, data) {
        const endpoint = this.baseUrl + id + "/OutOfOffice/";
        return axios.post(endpoint, data);
    }

    disableOutOfOffice(id) {
        const endpoint = this.baseUrl + id + "/OutOfOffice/";
        return axios.delete(endpoint);
    }

    getOutOfOfficeSettings(id){
        const endpoint = this.baseUrl + id + "/OutOfOfficeSettings/";
        return axios.get(endpoint);
    }

    resolveTemplate(id, templateId) {
        const endpoint = this.baseUrl + id + "/ResolveTemplate/" + templateId;
        return axios.get(endpoint);
    }
}