import axios from 'axios';
import { EntityService } from './EntityService';

export class UserService extends EntityService {
    constructor() {
        super("Users");
    }

    setPassword(id, model) {
        const endpoint = this.baseUrl + id + "/SetPassword/";
        return axios.put(endpoint, model);
    }

    changePassword(model) {
        const endpoint = this.baseUrl + "ChangePassword/";
        return axios.put(endpoint, model);
    }

    sendConfirmEmail(model) {
        const endpoint = this.baseUrl + "SendConfirmEmail/";
        return axios.post(endpoint, model);      
    }
}