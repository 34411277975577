import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { MessageTemplateForm } from './Form';
import { MessageTemplateService } from '../../services/MessageTemplateService';

export class EditMessageTemplateContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            messageTemplate: {},
            isMessageTemplateLoaded: false
        };

        this.MessageTemplateService = new MessageTemplateService();

        this.updateMessageTemplate = this.updateMessageTemplate.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.messageTemplateId = this.props.match.params.messageTemplateId * 1;
    }

    componentDidMount() {
        this.MessageTemplateService.getById(this.messageTemplateId).then(
            response => {
                const messageTemplate = {
                    ...response.data
                }
                this.setState({
                    messageTemplate: messageTemplate,
                    isMessageTemplateLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain specified message template', 'Error', 10000);
            }
        );        
    }

    updateMessageTemplate(messageTemplate) {
        var model = {
            ...messageTemplate
        };

        this.MessageTemplateService.update(this.messageTemplateId, model).then(
            response => {
                // Alert the client that the messageTemplate has been updated
                NotificationManager.success('The message template ' + messageTemplate.name + ' was updated successfully', 'Message Template Updated', 10000);
            },
            error => {
                // Alert the client that the messageTemplate has NOT been updated
                NotificationManager.error('Message Template was not updated', 'Error', 10000);
            }
        );
    }

    handleSaveClick(e) {
        // We will use the reference to the UserForm component
        // To call the relevant function/method that will submit/process the form data.
        if (this.messageTemplateForm && this.messageTemplateForm.handleSubmit) {
            const form = this.messageTemplateForm.state.form
            if (form.isValid) {
                this.messageTemplateForm.handleSubmit(e);
            }
            else {
                this.messageTemplateForm.setState({
                    form: {
                        ...form,
                        isSubmitAttempted: true
                    }
                });
                NotificationManager.error('Please fix the form errors', 'Error', 10000);
            }
        }
    }

    render() {
        const isLoaded = this.state.isMessageTemplateLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/mailbox-manager/message-templates" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Message Templates List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Edit Message Template Form..."
        };

        return (
            <Screen title="Edit Message Template" menu={screenMenu} loader={screenLoader}>
                <MessageTemplateForm
                    ref={(el) => this.messageTemplateForm = el}
                    data={this.state.messageTemplate}
                    handleSave={this.updateMessageTemplate}                    
                />
            </Screen>
        );
    }
}