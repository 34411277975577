import _ from 'lodash';
import moment from 'moment';

function getRequiredValidator(errorMsg) {
    return {
        name: "required",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' is required'
                field.isValid = field.value.toString().length > 0;
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    };
}

function getRequiredValueValidator(value, errorMsg) {
    return {
        name: "required-value",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' is invalid';

                if(_.isArray(value)) {
                    field.isValid = _.includes(value, field.value);
                }
                else {
                    field.isValid = field.value === value;
                }

                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    };
}

function getInvalidValueValidator(value, errorMsg) {
    return {
        name: "invalid-value",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' is  is invalid';

                if(_.isArray(value)) {
                    field.isValid = !_.includes(value, field.value);
                }
                else {
                    field.isValid = field.value !== value;
                }

                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    };
}

function getRegexValidator(pattern, errorMsg) {
    return {
        name: "regex",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' is invalid'
                field.isValid = pattern.test(field.value);
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getEmailRegexValidator(errorMsg) {
    return {
        name: "email-regex",
        validate: (field) => {
            if(field.isValid) {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' is invalid'
                field.isValid = pattern.test(field.value);
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getPasswordRegexValidator(errorMsg) {
    return {
        name: "password-regex",
        validate: (field) => {
            if(field.isValid) {
                const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&*])(?=.{6,})");
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' does not meet the requirements'
                field.isValid = pattern.test(field.value);
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getNumberRangeValidator(min, max, errorMsg) {
    return {
        name: "number-range",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' must be in the range ' + min + ' to ' + max; 
                const numValue = field.value * 1;
                field.isValid = numValue >= min && numValue <= max;
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getIsDateValidator(errorMsg) {
    return {
        name: "is-date",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' must be valid date';
                const date = moment(field.value);
                field.isValid = date.isValid();
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getIsPastDateValidator(isValidateTimeEnabled, errorMsg) {
    return {
        name: "is-past-date",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' must be in the past';
                const date = moment(field.value);
                const now = isValidateTimeEnabled ? moment() : moment().startOf('day');
                field.isValid = date.isBefore(now);
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getIsTodayOrPastDateValidator(isValidateTimeEnabled, errorMsg) {
    return {
        name: "is-todaye-or-past-date",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' must be today or in the past';
                const date = moment(field.value);
                const now = isValidateTimeEnabled ? moment() : moment().startOf('day');
                field.isValid = date.isSameOrBefore(now);
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getIsFutureDateValidator(isValidateTimeEnabled, errorMsg) {
    return {
        name: "is-past-date",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' must be in the future';
                const date = moment(field.value);
                const now = isValidateTimeEnabled ? moment() : moment().startOf('day');
                field.isValid = date.isAfter(now);
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getIsTodayOrFutureDateValidator(isValidateTimeEnabled, errorMsg) {
    return {
        name: "is-todaye-or-past-date",
        validate: (field) => {
            if(field.isValid) {
                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' must be today or in the future';
                const date = moment(field.value);
                const now = isValidateTimeEnabled ? moment() : moment().startOf('day');
                field.isValid = date.isSameOrAfter(now);
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

function getDateRangeValidator(startDate, endDate, isValidateTimeEnabled, errorMsg) {
    return {
        name: "date-range",
        validate: (field) => {
            if(field.isValid) {
                
                const start = isValidateTimeEnabled ? moment(startDate) : moment(startDate).startOf('day');
                const end = isValidateTimeEnabled ? moment(endDate) : moment(endDate).startOf('day');

                const freindlyStart = isValidateTimeEnabled ? start.format('DD/MM/YYYY HH:mm:ss') : start.format('DD/MM/YYYY');
                const freindlyEnd = isValidateTimeEnabled ? end.format('DD/MM/YYYY HH:mm:ss') : end.format('DD/MM/YYYY');

                const errorMessage = errorMsg && errorMsg.length > 0 ? errorMsg : field.label + ' must be between ' +  freindlyStart + ' and ' + freindlyEnd;
                const date = moment(field.value);
                
                // NOTE:
                // The fourth parameter for isBetween() specifies inclusivity.
                //  - [] - means inclusive of both start and end date
                //  - () - means NOT inclusive of both start and end date
                //  - [) - means inclusive of start date ONLY
                //  - (] - means inclusive of end date ONLY

                field.isValid = isValidateTimeEnabled ? date.isBetween(startDate, endDate, null, '[]') : date.isBetween(startDate, endDate, 'day', '[]');
                field.errorMessage = field.isValid ? '' : errorMessage;
            }
        }
    }
}

export const Validators = {
    // Standard Validators
    required: getRequiredValidator,
    requiredValue: getRequiredValueValidator,
    invalidValue: getInvalidValueValidator,

    // Format Validators
    regex: getRegexValidator,
    emailFormat: getEmailRegexValidator,
    passwordFormat: getPasswordRegexValidator,

    // Number validators
    numberRange: getNumberRangeValidator,

    // Date Validators
    isDate: getIsDateValidator,
    isPastDate: getIsPastDateValidator,
    isTodayOrPastDate: getIsTodayOrPastDateValidator,
    isFutureDate: getIsFutureDateValidator,
    isTodayOrFutureDate: getIsTodayOrFutureDateValidator,
    dateRange: getDateRangeValidator
}