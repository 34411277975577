import { BaseForm } from '.';

export class Form extends BaseForm {
    constructor(props) {
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data), this should setup the form accordingly.
        super(props, props.definition, props.data);
    }

    // This is essentially a wrapper to create a dynamic form based on BaseForm
    // It's purpose is to take the relevant data from props and pass them to the constructor.
    // This saves having to create a derived component that need to supply multiple parameters.

    // UAGSE:
    // <Form 
    //     definition={this.state.formDefinition}
    //     data ={this.state.entity}
    //     handleSave={this.handleSave}
    //     
    //     hideSaveButton
    // /> *
}