import axios from 'axios';
import { EntityService } from './EntityService';

export class BulkActionsService extends EntityService {
    constructor() {
        super("ActiveDirectory/BulkActions");
    }

    assignTemplatesToMailboxes(model){
        const endpoint = this.baseUrl + "AssignTemplatesToMailboxes";
        return axios.post(endpoint, model);
    }
}