import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Role } from '../../enums';
import { PrivateRoute } from '../AuthApp/PrivateRoute'
import { LogoutForm } from '../AuthApp/LogoutForm';
import { DashboardContainer } from '../Dashboard/Container';
import { ErrorPage, NotFound, NotAuthorised, ReleaseNotes } from '../Pages';
import { UsersListContainer, CreateUserContainer, EditUserContainer } from '../Users';
import { RolesListContainer, CreateRoleContainer, EditRoleContainer } from '../Roles';
import { MailboxesListContainer } from '../Mailboxes';
import { MessageTemplatesListContainer, CreateMessageTemplateContainer, EditMessageTemplateContainer } from '../MessageTemplates';

export function Router(props) {  
    const errorsPath = 'error';
    const releaseNotesPath = 'release-notes';
    const usersPath = 'users';
    const rolesPath = 'roles';
    const mailboxesPath = "mailbox-manager/mailboxes";
    const messageTemplatesPath = 'mailbox-manager/message-templates';

    return (
        <Switch>            
            <PrivateRoute exact path='/' component={DashboardContainer} roles={[Role.SuperAdmin, Role.Admin, Role.Standard]} />

            <PrivateRoute exact path={'/' + usersPath + ''} component={UsersListContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + usersPath + '/create'} component={CreateUserContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + usersPath + '/edit/:userId'} component={EditUserContainer} roles={[Role.SuperAdmin, Role.Admin]} />

            <PrivateRoute exact path={'/' + rolesPath + ''} component={RolesListContainer} roles={[Role.SuperAdmin]} />
            <PrivateRoute path={'/' + rolesPath + '/create'} component={CreateRoleContainer} roles={[Role.SuperAdmin]} />
            <PrivateRoute path={'/' + rolesPath + '/edit/:roleId'} component={EditRoleContainer} roles={[Role.SuperAdmin]} />

            <PrivateRoute exact path={'/' + mailboxesPath + ''} component={MailboxesListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.Standard]} />

            <PrivateRoute exact path={'/' + messageTemplatesPath + ''} component={MessageTemplatesListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.Standard]} />
            <PrivateRoute path={'/' + messageTemplatesPath + '/create'} component={CreateMessageTemplateContainer} roles={[Role.SuperAdmin, Role.Admin, Role.Standard]} />
            <PrivateRoute path={'/' + messageTemplatesPath + '/edit/:messageTemplateId'} component={EditMessageTemplateContainer} roles={[Role.SuperAdmin, Role.Admin, Role.Standard]} />

            <Route path='/logout' render={() => <LogoutForm user={props.data.user} handleLogout={props.actions.logout} />} />

            <Route exact path={'/' + errorsPath} component={ErrorPage} />
            <Route path={'/' + errorsPath + '/not-authorised'} component={NotAuthorised} />
            <Route path={'/' + errorsPath + '/not-found'} component={NotFound} />
            <Route path={'/' + releaseNotesPath + ''} component={ReleaseNotes} />

            <Redirect to={'/' + errorsPath + '/not-found'} />
        </Switch>
    );
}