import React, { useState, useEffect } from 'react';
import { NotificationManager } from '../../helpers/NotificationManager';
import { MailboxService } from '../../services/MailboxService';
import { MessageTemplateService } from '../../services/MessageTemplateService';
import { OutOfOfficeSettingsService } from '../../services/OutOfOfficeSettingsService';
import { OutOfOfficeSettingsForm } from './OutOfOfficeSettingsForm';
import { SimpleLoader } from '../Common/SimpleLoader';

export const OutOfOfficeSettings = props => {
    const [outOfOfficeSettings, setOutOfOfficeSettings] = useState({});
    const [messageTemplateOptions, setMessageTemplateOptions] = useState([]);
    const [isMessageTemplatesLoaded, setIsMessageTemplatesLoaded] = useState(false);
    const [isFormLoaded, setIsFormLoaded] = useState(false);

    const userId = props.mailbox != null ? props.mailbox.id : 0;

    // Init.  
    useInitMount(userId);
    function useInitMount(userId) {
        useEffect(() => {
            const mailboxService = new MailboxService();
            const messageTemplateService = new MessageTemplateService();

            // Load user template
            mailboxService.getOutOfOfficeSettings(userId).then(
                response => {
                    const outOfOfficeSettings = {
                        id: response.data.id * 1,
                        adUserId: response.data.adUserId,
                        internalReplyTemplateId: response.data.internalReplyTemplateId,
                        internalReplyTemplateContent: response.data.internalReplyTemplate != null ? response.data.internalReplyTemplate.content : "",
                        externalReplyTemplateId: response.data.externalReplyTemplateId,
                        externalReplyTemplateContent: response.data.externalReplyTemplate != null ? response.data.externalReplyTemplate.content : "",
                    }
                    setOutOfOfficeSettings(outOfOfficeSettings);
                    setIsFormLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain out of office settings', 'Out of Office Settings :: Error', 10000);
                }
            )

            // Loads message templates
            messageTemplateService.getListForUser(userId, 1, 1000, "Name", "desc").then(
                response => {
                    const messageTemplates = response.data.results.map(messageTemplate => {
                        return {
                            id: messageTemplate.id,
                            name: messageTemplate.name,
                            content: messageTemplate.content
                        };
                    });

                    setMessageTemplateOptions(messageTemplates);
                    setIsMessageTemplatesLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain message templates', 'Message Templates :: Error', 10000);
                }
            );
        }, [userId]);
    }

    const handleSave = (e) => {
        const outOfOfficeSettingsService = new OutOfOfficeSettingsService();
        const outOfOfficeSettingsId = outOfOfficeSettings.id;

        outOfOfficeSettingsService.update(outOfOfficeSettingsId, e).then(
            response => {
                // Alert the client that the user has been updated
                NotificationManager.success('OOO Settings were updated successfully', 'Settings Updated', 10000);
            },
            error => {
                // Alert the client that the user has NOT been updated
                NotificationManager.error('OOO Settings were not updated', 'Error', 10000);
            }
        );
    }

    const isLoaded = isMessageTemplatesLoaded && isFormLoaded;

    return (
        <SimpleLoader active={!isLoaded} spinner text="Loading...">
            <OutOfOfficeSettingsForm
                mailbox={props.mailbox}
                data={outOfOfficeSettings}
                handleSave={handleSave}
                messageTemplateOptions={messageTemplateOptions}
            />
        </SimpleLoader>
    );
}