import React from 'react';
import { Route, Switch } from "react-router-dom";
import { LoginFormContainer } from './LoginFormContainer';
import { LogoutForm } from './LogoutForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ErrorPage, NotFound } from '../Pages';
import { ResetPasswordForm } from './ResetPasswordForm';
import { ConfirmAccountForm } from './ConfirmAccountForm';

export function Router(props) {
    const errorsPath = 'error';

    return (
        <Switch>
            <Route exact path={'/' + errorsPath} component={ErrorPage} />
            <Route path={'/' + errorsPath + '/not-found'} component={NotFound} />     

            <Route exact path="/confirm-account" component={ConfirmAccountForm}/>    
            <Route exact path="/forgot-password" component={ForgotPasswordForm}/>
            <Route exact path="/reset-password" component={ResetPasswordForm}/>
            <Route path='/logout' render={() => <LogoutForm user={props.data.user} handleLogout={props.actions.logout} />} />
            <Route render={() => <LoginFormContainer user={props.data.user} handleLogin={props.actions.login} />} />
        </Switch>
    );
}