import React, { useState, useEffect } from 'react';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { MailboxesList } from './List';
import { EntityService } from '../../services/EntityService';

export const MailboxesListContainer = props =>  {
    const [state, setState] = useState({
        mailboxes: [],
        isMailboxesLoaded: false,
        isSearchingActive: false
    });  

    // Services
    const [adUsersService] = useState(new EntityService("ActiveDirectory/Users"));

    // Load initial data
    useEffect(() => {            
        const handleResponse = (response) => {
            const mailboxes = response.data.results.map(mailbox => {
                return {
                    ...mailbox
                };
            });
            setState(prevState => { 
                return {
                    ...prevState,
                    mailboxes: mailboxes,
                    isMailboxesLoaded: true,
                    isSearchingActive: false
                };
            });      
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Mailboxes', 'Error', 10000);
        };
        
        adUsersService.getList(1, 1000, 'FirstName', 'asc').then(handleResponse, handleError);

    }, [adUsersService]);

    const handleSearch = (searchText) => {
        setState({ ...state, isSearchingActive: true });

        const handleResponse = (response) => {
            const mailboxes = response.data.results.map(mailbox => {
                return {
                    ...mailbox
                };
            });
            setState({ 
                ...state,
                mailboxes: mailboxes,
                isMailboxesLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Mailboxes', 'Error', 10000);
        };

        if (searchText && searchText.length > 0) {
            adUsersService.search(searchText, 1, 1000, 'FirstName', 'asc').then(handleResponse, handleError);
        }
        else {
            adUsersService.getList(1, 1000, 'FirstName', 'asc').then(handleResponse, handleError);
        }
    };

    const isLoaded = state.isMailboxesLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = "";
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Mailboxes..."
    };

    return (
        <Screen title="Mailboxes" menu={screenMenu} loader={screenLoader}>
            <MailboxesList
                items={state.mailboxes}
                handleSearch={handleSearch}
            />
        </Screen>
    );
}
