import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { MessageTemplatesList } from './List';
import { MessageTemplateService } from '../../services/MessageTemplateService';
import { Can } from '../RBAC/Can';

export const MessageTemplatesListContainer = props =>  {
    const [state, setState] = useState({
        messageTemplates: [],
        isMessageTemplatesLoaded: false,
        isSearchingActive: false
    });  

    // MessageTemplate Service
    const [messageTemplateService] = useState(new MessageTemplateService());

    // Load initial data
    useEffect(() => {            
        const handleResponse = (response) => {
            const messageTemplates = response.data.results.map(messageTemplate => {
                return {
                    ...messageTemplate
                };
            });
            setState(prevState => { 
                return {
                    ...prevState,
                    messageTemplates: messageTemplates,
                    isMessageTemplatesLoaded: true,
                    isSearchingActive: false
                };
            });      
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Message Templates', 'Error', 10000);
        };
        
        messageTemplateService.getList(1, 1000, 'Name', 'asc').then(handleResponse, handleError);

    }, [messageTemplateService]);

    const handleSearch = (searchText) => {
        setState({ ...state, isSearchingActive: true });

        const handleResponse = (response) => {
            const messageTemplates = response.data.results.map(messageTemplate => {
                return {
                    ...messageTemplate
                };
            });
            setState({ 
                ...state,
                messageTemplates: messageTemplates,
                isMessageTemplatesLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Message Templates', 'Error', 10000);
        };

        if (searchText && searchText.length > 0) {
            messageTemplateService.search(searchText, 1, 1000, 'Name', 'asc').then(handleResponse, handleError);
        }
        else {
            messageTemplateService.getList(1, 1000, 'Name', 'asc').then(handleResponse, handleError);
        }
    };

    const handleEdit = (messageTemplate, e) => {
        e.preventDefault();
        props.history.push('/mailbox-manager/message-templates/edit/' + messageTemplate.id);
    };

    const handleDelete = (messageTemplate, e) => {
        e.preventDefault();

        messageTemplateService.delete(messageTemplate.id).then(
            response => { 
                const messageTemplates = _.filter(state.messageTemplates, x => x.id !== messageTemplate.id);
                setState({ ...state, messageTemplates: messageTemplates });
                NotificationManager.success('The message template ' + messageTemplate.name + ' was deleted successfully', 'Message Template Deleted', 10000);
            },
            error => {
                NotificationManager.error('Message Template was not deleted', 'Error', 10000);
            }
        );
    };

    const isLoaded = state.isMessageTemplatesLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="messageTemplates:create"
            yes={() => (
                <>
                    <NavLink to="/mailbox-manager/message-templates/create" className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Create Message Template
                     </NavLink>
                </>
            )}
        />
    );
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Message Templates..."
    };

    return (
        <Screen title="Message Templates" menu={screenMenu} loader={screenLoader}>
            <MessageTemplatesList
                items={state.messageTemplates}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </Screen>
    );
}
