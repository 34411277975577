

import React from 'react';
import { Keys } from '../../enums';
import { EncodedStorageService } from '../../services/EncodedStorageService';

export const RoleCondition = ({roles, children}) => {
    const storage = new EncodedStorageService();
    const currentUser = storage.get(Keys.AuthUser);

    // If the user's current role is not in the allowed roles list, then we will not display the children. 
    return (roles && roles.indexOf(currentUser.role) >0 -1) ? (
        <>
            {children}
        </>
    ) : null;
}