import React from 'react';
import { NavLink } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Alert }  from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';
import { RoleActionsButton } from './ActionsButton';

export function RolesList(props) {
    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={'/roles/edit/' + row.id}>{row.name}</NavLink>
            }
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <RoleActionsButton 
                        role={row} 
                        handleEdit={props.handleEdit} 
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={props.handleSearch} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="name"
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No Roles were found</Alert>
            }
        </>
    );
}

