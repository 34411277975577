import axios from 'axios';
import { EntityService } from './EntityService';

export class MessageTemplateService extends EntityService {
    constructor() {
        super("MailboxManager/MessageTemplates");
    }

    getListForUser(id, page, size, sort, direction) {
        const endpoint = this.baseUrl + "GetForUser/" + id;
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }
}