import React from 'react';
import { BaseForm, Validators } from '../FormBuilder';
import { Row, Col } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';

export class OutOfOfficeSettingsForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "internalReplyTemplateId",
                    label: "Template",
                    editor: {
                        name: "dropdown",
                        options: props.messageTemplateOptions
                    },
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "internalReplyTemplateContent",
                    label: "Content",
                    editor: "textarea"                            
                },                
                {
                    name: "externalReplyTemplateId",
                    label: "Template",
                    editor: {
                        name: "dropdown",
                        options: props.messageTemplateOptions
                    },
                    validators: [
                        Validators.required()
                    ]                 
                },
                {
                    name: "externalReplyTemplateContent",
                    label: "Content",
                    editor: "textarea"                            
                }
            ]
        };

        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);   

        console.log(props);
    }

    getModel(fields) {
        // This function will override the logic to generate the model that is returned after submitting the form.
        // The generated model will be passed to the relevant handleSave() event, where it can be used to process the data accordingly.

        // Get the model that is generated by the BaseForm.
        // This gets the value for each field defined within the form and returns it as an object.
        // We can use this as the base model to work from and modify it accordingly before we return it.    
        const model = {
            adUserId: this.props.data.adUserId,
            internalReplyTemplateId: fields.internalReplyTemplateId.value * 1,
            externalReplyTemplateId: fields.externalReplyTemplateId.value * 1,
            isAutoSetHolidayLeaveOofDisabled: false,
            isAutoSetSickLeaveOofDisabled: false
        };

        return model;
    }

    updateForm(fields) {
        var model = this.getModel(fields);

        const internalReplyTemplate = fields.internalReplyTemplateId.editor.options.filter(x => x.id === model.internalReplyTemplateId);
        const externalReplyTemplate = fields.externalReplyTemplateId.editor.options.filter(x => x.id === model.externalReplyTemplateId);

        // Sets content value onChange
        fields.internalReplyTemplateContent.value = internalReplyTemplate[0] === undefined ? "" : internalReplyTemplate[0].content;
        fields.externalReplyTemplateContent.value = externalReplyTemplate[0] === undefined ? "" : externalReplyTemplate[0].content;
    }

    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {  
        this.updateForm(fields);        
        return (
            <Row form>
                <Col xs="12" md="12">  
                <h5>{this.props.mailbox.displayName}</h5>
                <small>{this.props.mailbox.email}</small>
                <hr/>                 
                    <h6>Internal Reply Template</h6>         
                    <FieldEditor field={fields.internalReplyTemplateId} actions={this.fieldActions}/>
                    <label>Content Preview</label>
                    <div className="form-control" style={{height: "auto"}} dangerouslySetInnerHTML={{__html: fields.internalReplyTemplateContent.value }}></div>                                       
                    
                    <hr/>
                    <h6>External Reply Template</h6>
                    <FieldEditor field={fields.externalReplyTemplateId} actions={this.fieldActions}/>
                    <label>Content Preview</label>
                    <div className="form-control" style={{height: "auto"}} dangerouslySetInnerHTML={{__html: fields.externalReplyTemplateContent.value }}></div>                
                    <br/>
                    </Col>                    
            </Row>
        );
    }
}