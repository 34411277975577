const rules = {
    SuperAdmin: {
        static: [
            // mailboxes
            "mailboxes:edit",
            // message templates
            "messageTemplates:view",
            "messageTemplates:create",
            "messageTemplates:edit",
            "messageTemplates:delete"
        ]
    },
    Admin: {
        static: [
            // mailboxes
            "mailboxes:edit",
            // message templates
            "messageTemplates:view",
            "messageTemplates:create",
            "messageTemplates:edit",
            "messageTemplates:delete"  
        ]
    },
    Standard: {
        static: [
            // message templates
            "messageTemplates:view"
        ]
    }
};
  
  export default rules;