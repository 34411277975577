import axios from 'axios';
import config from '../config';

export class EntityService {
    constructor(basePath) {
        this.basePath = basePath;
        this.baseUrl = config.baseApiUrl + this.basePath + '/';
    }

    getById(id) {
        const endpoint = this.baseUrl + id;
        return axios.get(endpoint);
    }

    getList(page, size, sort, direction) {
        const endpoint = this.baseUrl;
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }

    search(query, page, size, sort, direction) {
        const endpoint = this.baseUrl + 'Search/';
        return axios.get(endpoint, {
            params: {
                text: query,
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }

    filteredSearch(query, type, page, size, sort, direction) {
        const endpoint = this.baseUrl + 'Search/';
        return axios.get(endpoint, {
            params: {
                text: query,
                type: type, 
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }

    create(entity) {
        const endpoint = this.baseUrl;
        return axios.post(endpoint, entity);
    }

    update(id, entity) {
        const endpoint = this.baseUrl + id;
        return axios.put(endpoint, entity);
    }

    patch(id, entity) {
        const endpoint = this.baseUrl + id;    
        return axios.patch(endpoint, entity, {
            //headers: {'Content-Type': 'application/json-patch+json'}  // THIS NEVER WORKS
        });        
    }

    delete(id) {
        const endpoint = this.baseUrl + id;
        return axios.delete(endpoint);
    }
}