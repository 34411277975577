import _ from 'lodash';
import { store } from 'react-notifications-component';

const baseNotificationSettings = {
    title: "Information!!",
    message: "This is from the base notification",
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"]
  };

function addDefaultNotification(message, title, time) {
    title = (title && title.length > 0) ? title : "Default";
    time = (time) ? time : 5000;

    store.addNotification({
        ...baseNotificationSettings,
        title: title,
        message: message,
        type: "info",
        dismiss: {
            duration: time,
            onScreen: true
        }
    });    
}

function addInfoNotification(message, title, time) {
    title = (title && title.length > 0) ? title : "Information";
    time = (time) ? time : 5000;

    store.addNotification({
        ...baseNotificationSettings,
        title: title,
        message: message,
        type: "info",
        dismiss: {
            duration: time,
            onScreen: true
        }
    });    
}

function addWarningNotification(message, title, time) {
    title = (title && title.length > 0) ? title : "Warning";
    time = (time) ? time : 5000;

    store.addNotification({
        ...baseNotificationSettings,
        title: title,
        message: message,
        type: "warning",
        dismiss: {
            duration: time,
            onScreen: true
        }
    });    
}

function addSuccessNotification(message, title, time) {
    title = (title && title.length > 0) ? title : "Success";
    time = (time) ? time : 5000;

    store.addNotification({
        ...baseNotificationSettings,
        title: title,
        message: message,
        type: "success",
        dismiss: {
            duration: time,
            onScreen: true
        }
    });    
}

function addErrorNotification(message, title, time) {
    title = (title && title.length > 0) ? title : "Error";
    time = (time) ? time : 5000;

    store.addNotification({
        ...baseNotificationSettings,
        title: title,
        message: message,
        type: "danger",
        dismiss: {
            duration: time,
            onScreen: true
        },
    });
}

function clearAll() {
    // TODO: 
    // Implement logic to clear all of the notification currently being displayed.
}

function handleApiErrorResponse(response, defaultMessage) {
    const errors = response.data.errors;
    if(errors && errors.length > 0) {
        _.forEach(errors, (err) => {
            addErrorNotification(err.message, 'Error', 0);
        });
    }
    else {
        const errorMessage = defaultMessage && defaultMessage.length > 0 ? defaultMessage : "An error has occurred";
        addErrorNotification(errorMessage, 'Error', 0);
    }
}

const NotificationManager = {
    info: addInfoNotification,
    warning: addWarningNotification,
    success: addSuccessNotification,
    error: addErrorNotification,
    default: addDefaultNotification,
    clearAll: clearAll,
    handleApiErrorResponse: handleApiErrorResponse
}

export {
    NotificationManager
};