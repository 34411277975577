import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { RoleForm } from './Form';
import { EntityService } from '../../services/EntityService';

export class EditRoleContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            role: {},
            isRoleLoaded: false
        };

        this.RoleService = new EntityService("Roles");

        this.updateRole = this.updateRole.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.roleId = this.props.match.params.roleId;

    }

    componentDidMount() {
        this.RoleService.getById(this.roleId).then(
            response => {
                const role = {
                    ...response.data
                }
                this.setState({
                    role: role,
                    isRoleLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain specified role', 'Error', 10000);
            }
        );        
    }

    updateRole(role) {
        var model = {
            ...role
        };

        this.RoleService.update(this.roleId, model).then(
            response => {
                // Alert the client that the role has been updated
                NotificationManager.success('The role ' + role.name + ' was updated successfully', 'Role Updated', 10000);
            },
            error => {
                // Alert the client that the role has NOT been updated
                NotificationManager.error('Role was not updated', 'Error', 10000);
            }
        );
    }

    handleSaveClick(e) {
        // We will use the reference to the UserForm component
        // To call the relevant function/method that will submit/process the form data.
        if (this.roleForm && this.roleForm.handleSubmit) {
            const form = this.roleForm.state.form
            if (form.isValid) {
                this.roleForm.handleSubmit(e);
            }
            else {
                this.roleForm.setState({
                    form: {
                        ...form,
                        isSubmitAttempted: true
                    }
                });
                NotificationManager.error('Please fix the form errors', 'Error', 10000);
            }
        }
    }

    render() {
        const isLoaded = this.state.isRoleLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/roles" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Roles List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Edit Role Form..."
        };

        return (
            <Screen title="Edit Role" menu={screenMenu} loader={screenLoader}>
                <RoleForm
                    ref={(el) => this.roleForm = el}
                    data={this.state.role}
                    handleSave={this.updateRole}
                    
                />
            </Screen>
        );
    }
}