import React from 'react';
import { CheckboxField, DatePickerField, DropdownField, MultipleCheckboxField, NumberField, TextField, TextAreaField, PasswordField } from '.';
import { RichTextEditorField } from './RichTextEditorField/RichTextEditorField';

export function FieldEditor({ field, actions }) {
    let editorId = '';
    
    if(typeof field.editor === 'string') {
        editorId = field.editor;
    }
    else if(typeof field.editor === 'object') {
        editorId = field.editor.name;
    }

    switch(editorId.toLowerCase()) {
        case "datepicker":
            return <DatePickerField field={field} actions={actions} options={field.editor.options} />;

        case "dropdown":
            return <DropdownField field={field} disableDefaultKey={false} actions={actions} options={field.editor.options} />;
        
        case "textarea":
            return <TextAreaField field={field} actions={actions} />;

        case "rte":
            return <RichTextEditorField field={field} actions={actions} options={field.editor.options}/>;

        case "multicheckbox":
            return <MultipleCheckboxField field={field} actions={actions} options={field.editor.options}/>;

        case "checkbox":
            return <CheckboxField field={field} actions={actions} />;

        case "number":
            return <NumberField field={field} actions={actions} maximum={field.editor.maximum} minimum={field.editor.minimum} step={field.editor.step} />
        
        case "password":
            return <PasswordField field={field} actions={actions} />;

        default:
            return <TextField field={field} actions={actions} />;
    }
}