

import React from 'react';
import { LoginForm } from './LoginForm';

export class LoginFormContainer extends React.Component {
    render() {
        return (
            <div>
                <p className="text-center">Login in to start your session</p>
                <LoginForm 
                    handleSave={this.props.handleLogin}
                    
                    submitButtonText="Login"
                    submitButtonPosition="full-width"
                    submitButtonColor="info"
                />
            </div>
        );
    }
}