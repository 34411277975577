import rules from "./rbac-rules";
import { Keys } from '../../enums';
import { EncodedStorageService } from '../../services/EncodedStorageService';

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const storage = new EncodedStorageService();
const currentUser = storage.get(Keys.AuthUser);
const role = currentUser != null ? currentUser.roles[0] : ""; 

export const Can = props =>
  check(rules, role, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};