import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTable from 'react-data-table-component';
import { Alert, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Row, Col  }  from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';
import { MailboxActionsButton } from './ActionsButton';
import { BasicModal } from '../Modals/Basic';
import { BulkApplyMessageTemplates } from '../MessageTemplates/BulkApplyMessageTemplates';

export const MailboxesList = props => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isBulkActionsModalOpen, setIsBulkActionsModalOpen] = useState(false);
    
    const toggle = () => { setIsDropdownOpen(!isDropdownOpen) };
    const toggleBulkActions = () => { setIsBulkActionsModalOpen(!isBulkActionsModalOpen) };

    const columns = [
        {
            name: 'Display Name',
            selector: 'displayName',
            sortable: true,
            ignoreRowClick: true
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true
        },
        {
            name: 'Company',
            selector: 'company',
            sortable: true
        },
        {
            name: 'Department',
            selector: 'department',
            sortable: true
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <MailboxActionsButton 
                        mailbox={row} 
                    />
                );
            }
        }
    ];

    // button click event to handle bulk actions!
    // render dropdown menu with option to 

    const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
    };

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <Row>
                <Col xs="12" md="4">
                    <ButtonDropdown direction="down" isOpen={isDropdownOpen} toggle={toggle} size="sm">
                        <DropdownToggle caret color="success" size="sm"><Icon.Grid className="feather"/> &nbsp;Bulk Actions</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={toggleBulkActions}><Icon.FileText className="feather" />&nbsp; Assign Templates</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </Col>
                <Col xs="12" md="8">
                    <TableSearchForm handleSearch={props.handleSearch} />
                </Col> 
            </Row>

            {/* Modal */}
            <BasicModal
                isOpen={isBulkActionsModalOpen}
                toggle={toggleBulkActions}
                title="Bulk Apply Message Templates"
            >
                <BulkApplyMessageTemplates selectedRows={selectedRows} />
            </BasicModal>

            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="firstName"
                        selectableRows       
                        onSelectedRowsChange={handleRowSelected}          
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No Mailboxes were found</Alert>
            }
        </>
    );
}

