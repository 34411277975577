import React from 'react';
import { Screen } from '../App/Screen';

export function ReleaseNotes() {
    return (
        <Screen title="Release Notes">
            <h3>V1.0.0</h3>
            <p><strong>What's New?</strong></p>
            <ul>
                <li>An intitutive and easy-to-read dashboard has been implemented</li>
                <li>Mailboxes can have their out of office configured</li>
                <li>Default message templates are available to create and set</li>
            </ul>
        </Screen>
    );
}