import React from 'react';
import DataTable from 'react-data-table-component';
import { Alert }  from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';
import { MessageTemplateActionsButton } from './ActionsButton';

export function MessageTemplatesList(props) {
    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true
        },
        {
            name: 'Content',
            selector: 'content',
            sortable: true,
            cell: (row) => {
                const strout = row.content.substring(0, 50)
                return (
                    <span>{strout}</span>
                );
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <MessageTemplateActionsButton 
                        messageTemplate={row} 
                        handleEdit={props.handleEdit} 
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={props.handleSearch} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="name"
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No MessageTemplates were found</Alert>
            }
        </>
    );
}

