import React from 'react';
import DataTable from 'react-data-table-component';
import { Alert }  from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';

export const ActiveDirectoryUsersList = props => {
    const columns = [
        {
            name: 'Display Name',
            selector: 'displayName',
            sortable: true,
            ignoreRowClick: true
        },
        {
            name: 'Telephone',
            selector: 'telephone',
            sortable: true
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true
        },
        {
            name: 'Company',
            selector: 'company',
            sortable: true
        },
        {
            name: 'Department',
            selector: 'department',
            sortable: true
        },        
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={props.handleSearch} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="firstName"       
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No Users were found</Alert>
            }
        </>
    );
}

