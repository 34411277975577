import React from 'react';
import { BaseForm, Validators } from '../FormBuilder';
import { Row, Col, Alert } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';
import { DropdownField } from '../FormBuilder/DropdownField';

export class OutOfOfficeStatusForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "statusId",
                    label: "Status",
                    editor: {
                        name: "dropdown",
                        options: props.statusOptions
                    }
                },
                {
                    name: "startDate",
                    label: "Start Date",
                    editor: {
                        name: "datepicker",
                        options: {
                            outputFormat: "YYYY-MM-DD"
                        }
                    },
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "endDate",
                    label: "End Date",
                    editor: {
                        name: "datepicker",
                        options: {
                            outputFormat: "YYYY-MM-DD"
                        }
                    },
                    validators: [
                        Validators.required()
                    ]
                },
            ]
        };

        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }

    getModel(fields) {
        // This function will override the logic to generate the model that is returned after submitting the form.
        // The generated model will be passed to the relevant handleSave() event, where it can be used to process the data accordingly.

        // Get the model that is generated by the BaseForm.
        // This gets the value for each field defined within the form and returns it as an object.
        // We can use this as the base model to work from and modify it accordingly before we return it.    
        const model = {
            statusId: fields.statusId.value * 1,
            start: fields.startDate.value,
            end: fields.endDate.value,          
        };

        return model;
    }

    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {
        return (
            <Row form>
                <Col xs="12" md="12">
                    <h5>{this.props.mailbox.displayName}</h5>
                    <small>{this.props.mailbox.email}</small>
                    <hr />
                    <DropdownField field={fields.statusId} disableDefaultKey={true} actions={this.fieldActions} options={this.props.statusOptions} />
                </Col>
                <Col xs="12" md="6">
                    <FieldEditor field={fields.startDate} actions={this.fieldActions} />
                </Col>
                <Col xs="12" md="6">
                    <FieldEditor field={fields.endDate} actions={this.fieldActions} />
                </Col>
                <Col xs="12" md="12">
                    <Alert color="warning">
                        <p><strong>Restrictions:</strong></p>
                        <ul>
                            <li>Start Time is set as Midnight (00:00)</li>
                            <li>End Time is set as 7AM (07:00)</li>
                        </ul>
                    </Alert>
                </Col>
            </Row>
        );
    }
}
