import React, { useState } from 'react';
import { Col, Alert, Button, PopoverHeader, PopoverBody, Collapse } from 'reactstrap';

export const MailboxesPopover = props => {
    const [isOpen, setIsOpen] = useState(false);

    const hasAny = props.mailboxes.length > 0 ? true : false;

    return (
        <>
            <PopoverHeader>Mailboxes</PopoverHeader>
            <PopoverBody>
                <p>Apply templates to <Button size="sm" onClick={() => setIsOpen(!isOpen)}>{props.mailboxes.length} mailboxes</Button></p>
                <Collapse isOpen={isOpen}>
                    {
                        hasAny ?
                            <Col xs="12">
                                <ul>
                                    {props.mailboxes.map((mailbox, mailboxIndex) => {
                                        return (
                                            <li key={mailboxIndex}>{mailbox.displayName}</li>
                                        )
                                    })}
                                </ul>
                            </Col> :
                            <Col xs="12"><Alert color="warning">No entries were found.</Alert></Col>
                    }
                </Collapse>
            </PopoverBody>
        </>
    );
}
