import React, { useState, useEffect } from 'react';
import { BaseForm, Validators } from '../FormBuilder';
import { Row, Col } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';
import { NotificationManager } from '../../helpers/NotificationManager';
import { BulkActionsService } from '../../services/BulkActionsService';
import { MessageTemplateService } from '../../services/MessageTemplateService';
import { SimpleLoader } from '../Common/SimpleLoader';
import { MailboxesPopover } from '../Mailboxes/MailboxesPopover';

export const BulkApplyMessageTemplates = props => {
    const [messageTemplateOptions, setMessageTemplateOptions] = useState([]);
    const [isMessageTemplatesLoaded, setIsMessageTemplatesLoaded] = useState(false);

    const mailboxes = props.selectedRows;

    // Init.  
    useInitMount();
    function useInitMount() {
        useEffect(() => {
            const messageTemplateService = new MessageTemplateService();

            // Loads message templates
            messageTemplateService.getList(1, 1000, "Name", "desc").then(
                response => {
                    const messageTemplates = response.data.results.map(messageTemplate => {
                        return {
                            id: messageTemplate.id,
                            name: messageTemplate.name,
                            content: messageTemplate.content
                        };
                    });

                    setMessageTemplateOptions(messageTemplates);
                    setIsMessageTemplatesLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain message templates', 'Message Templates :: Error', 10000);
                }
            );
        }, []);
    }

    const handleSave = (e) => {
        const bulkActionsService = new BulkActionsService();
        bulkActionsService.assignTemplatesToMailboxes(e).then(
            response => {
                // Alert the client that the templates have been applied
                NotificationManager.success('Message Templates were applied successfully', 'Settings Updated', 10000);
            },
            error => {
                // Alert the client that the templates have NOT been applied
                NotificationManager.error('Message Templates were not applied', 'Error', 10000);
            }
        );
    }

    const isLoaded = isMessageTemplatesLoaded;

    return (
        <SimpleLoader active={!isLoaded} spinner text="Loading...">
            <BulkApplyMessageTemplatesForm
                mailboxes={mailboxes}
                handleSave={handleSave}
                messageTemplateOptions={messageTemplateOptions}
            />
        </SimpleLoader>
    );
}

export class BulkApplyMessageTemplatesForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "internalReplyTemplateId",
                    label: "Template",
                    editor: {
                        name: "dropdown",
                        options: props.messageTemplateOptions
                    },
                    validators: [
                        Validators.required()
                    ],
                    value: "1"
                },
                {
                    name: "internalReplyTemplateContent",
                    label: "Content",
                    editor: "textarea"
                },
                {
                    name: "externalReplyTemplateId",
                    label: "Template",
                    editor: {
                        name: "dropdown",
                        options: props.messageTemplateOptions
                    },
                    validators: [
                        Validators.required()
                    ],
                    value: "2"
                },
                {
                    name: "externalReplyTemplateContent",
                    label: "Content",
                    editor: "textarea"
                }
            ]
        };

        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }

    getModel(fields) {
        // This function will override the logic to generate the model that is returned after submitting the form.
        // The generated model will be passed to the relevant handleSave() event, where it can be used to process the data accordingly.

        // Get the model that is generated by the BaseForm.
        // This gets the value for each field defined within the form and returns it as an object.
        // We can use this as the base model to work from and modify it accordingly before we return it.    
        const model = {
            mailboxIds: this.props.mailboxes.map(mailbox => mailbox.id),
            internalReplyTemplateId: fields.internalReplyTemplateId.value * 1,
            externalReplyTemplateId: fields.externalReplyTemplateId.value * 1,
        };

        return model;
    }

    updateForm(fields) {
        var model = this.getModel(fields);

        const internalReplyTemplate = fields.internalReplyTemplateId.editor.options.filter(x => x.id === model.internalReplyTemplateId);
        const externalReplyTemplate = fields.externalReplyTemplateId.editor.options.filter(x => x.id === model.externalReplyTemplateId);

        // Sets content value onChange
        fields.internalReplyTemplateContent.value = internalReplyTemplate[0].content;
        fields.externalReplyTemplateContent.value = externalReplyTemplate[0].content;
    }

    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {
        this.updateForm(fields);
        return (
            <Row form>
                <Col xs="12" md="12">
                    <MailboxesPopover mailboxes={this.props.mailboxes} />
                    <hr style={{marginTop: 0}}/>
                    <h6>Internal Reply Template</h6>
                    <FieldEditor field={fields.internalReplyTemplateId} actions={this.fieldActions} />
                    <FieldEditor field={fields.internalReplyTemplateContent} actions={this.fieldActions} />
                    <hr />
                    <h6>External Reply Template</h6>
                    <FieldEditor field={fields.externalReplyTemplateId} actions={this.fieldActions} />
                    <FieldEditor field={fields.externalReplyTemplateContent} actions={this.fieldActions} />
                </Col>
            </Row>
        );
    }
}