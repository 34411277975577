import React, { useState, useEffect } from 'react';
import { FormGroup, Label, CustomInput } from 'reactstrap';
import _ from 'lodash';

export const MultipleCheckboxField = props => {   
    const [selectedItems, setSelectedItems] = useState([]);  
    const [options, setOptions] = useState([]); 
    const [isLoaded, setIsLoaded] = useState(false); 
    
    const hasOptions = props.options && props.options.length > 0;      

    // Every Change
    useEffect(() => {        
        setIsLoaded(true);       

        if (props.options !== options){
            setSelectedItems([]);
            setOptions(props.options);
        }

        // Create new multiCheckbox object
        const multiCheckbox = {
            target: {
                name: props.field.name,
                type: 'multicheckbox',
                value: selectedItems,
            }
        };

        props.actions.handleChange(multiCheckbox);
    }, [selectedItems, props.field.name, props.actions, props.options, options]);


    const handleChange = (e) => {
        const id = e.target.id * 1;
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedItems([...selectedItems, id]);
        }
        else if (!isChecked) {
            setSelectedItems(selectedItems.filter(item => item !== id));
        }     
    };  

    return (
        isLoaded ? 
        <FormGroup className={props.actions.getErrorClass(props.field)}>
            <Label for={props.field.name}>{props.field.label}&nbsp;&nbsp;&nbsp;<small>{props.actions.getErrorMessage(props.field)}</small></Label>
            <div>
                {
                    hasOptions ? props.options.map((opt) => {                
                        var isChecked = _.filter(selectedItems, x => x === opt.id).length > 0 ? true : false;
                        opt.value = isChecked;                
                        return (
                            <CustomInput key={opt.id} type="checkbox" name={opt.name} id={opt.id} label={opt.name} checked={opt.value} onChange={handleChange}/>
                        );                    
                    }) : <p></p>
                }
            </div>
        </FormGroup> : <>Loading options...</>
    );
}



